<template>
	<v-app>
		<v-main>
			<transition name="fade-in-out" appear>
				<router-view></router-view>
			</transition>
		</v-main>
	</v-app>
</template>

<script>
import { mapMutations } from 'vuex';
import { MUTATION_TYPES } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout';
import {loadGTM} from "./cookie-bar";

export default {
	name: 'App',
	created() {
		loadGTM();
		this[MUTATION_TYPES.SET_CAMPAIGN_IDENTIFIER](process.env.VUE_APP_CAMPAIGN_ID);
	},
	methods: {
		...mapMutations(VUEX_BUYOUT.NAMESPACE, [MUTATION_TYPES.SET_CAMPAIGN_IDENTIFIER,]),
	}
};
</script>
