import CookieBar from "@olc/cookie-bar";
import "@olc/cookie-bar/dist/udm/cookie-bar.css";

// create cookie bar with custom config
const cookieBar = new CookieBar({
	reloadOnUpdate: false,
	expires: 365 * 10,
	colors: {
		primary: '#0d8131'
	}
});

cookieBar.addOption(
	'necessarily',
	{
		cs: {
			title: 'Nutné',
			subTitle: '',
			description:
				'Technické cookies jsou nezbytné pro správné fungování této stránky a neuchovávají ' +
				'o uživateli identifikovatelné údaje. Tyto cookies nelze zakázat.'
		}
	},
	true,
	true
);

cookieBar.addOption(
	'advertising',
	{
		cs: {
			title: 'Marketing a další',
			subTitle: '',
			description:
				'Tyto cookies nám pomáhají zobrazit personalizovanou nabídku na reklamních plochách na ' +
				'jiných webových stránkách a analyzovat souhrnná data.'
		}
	},
	false,
	true
);

cookieBar.setLanguage('cs', {
	bar_mainHeader: 'Soubory cookies',
	bar_description:
		'Naše webové stránky používají soubory cookies. Některé z nich jsou pro ' +
		'správný chod webu nutné. Další z nich vylepšují Váš uživatelský zážitek a ' +
		'pomáhají nám náš web rozvíjet. Váš výběr budete moci kdykoliv změnit.',
	button_continue: 'Povolit vše'
});

export function loadGTM(gtmId='GTM-MG29MB6') {
  window.dataLayer = window.dataLayer || [];

	function gtag() {
		window.dataLayer.push(arguments);
	}
  // Improve ad click measurement quality (optional)
	gtag('set', 'url_passthrough', true);
	// Further redact your ads data (optional)
	gtag('set', 'ads_data_redaction', true);

	// Global site tag (gtag.js) - Google Analytics - OLC
	gtag('js', new Date());
	gtag('config', gtmId);

	((w, d, s, l) => {
		w[l] = w[l] || [];
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js'
		});
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s);
		j.async = true;
		j.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer');
}

cookieBar.on('completion', cb => {
	const marketingConsent = cb.getOption("advertising").enable ? "granted" : "declined";
	const gtmMsg = ["consent", "default", {personalization_storage: marketingConsent }];
	window.dataLayer.push(gtmMsg);

	function fbq() {
		window.fbq.callMethod
			? window.fbq.callMethod.apply(window.fbq, arguments)
			: window.fbq.queue.push(arguments);
	}
	if (marketingConsent === 'granted') {
		// Global site tag (gtag.js) - Google Ads: 762100629 - iStores
		window.dataLayer.push('js', new Date());
		window.dataLayer.push('config', 'AW-762100629');

		((w, d, s, l) => {
			w[l] = w[l] || [];
			w[l].push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js'
			});
			const f = d.getElementsByTagName(s)[0];
			const j = d.createElement(s);
			j.async = true;
			j.src = `https://www.googletagmanager.com/gtag/js?id=AW-762100629`;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer');

		// facebook
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod
					? n.callMethod.apply(n, arguments)
					: n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(
			window,
			document,
			'script',
			'https://connect.facebook.net/en_US/fbevents.js'
		);
		fbq('init', '828984517231025'); // Insert your pixel ID here.
		fbq('track', 'PageView');

		const noScriptFBQ = document.createElement('noscript');
		noScriptFBQ.innerHTML = `<noscript>
											<img
												height="1"
												width="1"
												style="display: none;"
												src="https://www.facebook.com/tr?id=828984517231025&ev=PageView&noscript=1"
												alt="..."	
											/>
										</noscript>`;
		document.body.prepend(noScriptFBQ);
	}
});

window.cookieBar = cookieBar;

// render cookie bar
cookieBar.render();
