import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import { MUTATION_TYPES } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { ACTION_TYPES } from 'js-mp/src/vue/store/buyout/_action-types';
import { store } from '../main';

Vue.use(VueRouter);


const routes = [
	{
		path: '/vykup/:userBuyoutCode',
		name: 'buyout',
		component: Home,
		beforeEnter: (to, from, next) => {
			store.commit(
				`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_BUYOUT_ONLINE}`,
				true
			);
			store.commit(
				`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_USER_BUYOUT_CODE}`,
				to.params.userBuyoutCode
			);
			store.dispatch(
				`${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.GET_BUYOUT_STATE}`
			);
			store.dispatch(
				`${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_BUYOUT}`
			);
			next();
		}
	},
	{
		path: '/:category?/:brand?/:model?/:color?/:quality?',
		name: 'home',
		component: Home,
		beforeEnter: (to, from, next) => {
			if (to.params.category && to.params.category === 'vykup') next('/');
			else next();
		}
	}
];


const router = new VueRouter({
	mode: 'history',
	routes
});

export default router;
