<template>
  <v-menu
    v-model="isVisible"
    :close-on-content-click="false"
    :min-width="280"
    :max-width="280"
    :nudge-left="130"
    :nudge-top="20"
    offset-y
    top
    content-class="toolTipIcon__content"
  >
    <template #activator="{ on }">
      <div
        :class="['toolTipIcon__iconWrapper cursor-pointer', isVisible ? 'is-open' : '']"
        :style="storeCoords"
        v-on="on"
        @click="showTooltip"
      >
        <div
          v-if="displayStoreCounter"
          class="toolTipIcon__counter"
        >
          {{ displayStoreCounter }}
        </div>
      </div>
    </template>
    <v-card flat>
      <div
        class="toolTipIcon__close cursor-pointer"
        @click="isVisible = false"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 18 19" fill="none"><path d="M1.53033 0.96967C1.23744 0.676777 0.762563 0.676777 0.46967 0.96967C0.176777 1.26256 0.176777 1.73744 0.46967 2.03033L7.93934 9.5L0.46967 16.9697C0.176777 17.2626 0.176777 17.7374 0.46967 18.0303C0.762563 18.3232 1.23744 18.3232 1.53033 18.0303L9 10.5607L16.4697 18.0303C16.7626 18.3232 17.2374 18.3232 17.5303 18.0303C17.8232 17.7374 17.8232 17.2626 17.5303 16.9697L10.0607 9.5L17.5303 2.03033C17.8232 1.73744 17.8232 1.26256 17.5303 0.969669C17.2374 0.676777 16.7626 0.676777 16.4697 0.969669L9 8.43934L1.53033 0.96967Z" fill="#1D2127"/></svg>
      </div>
      <div
        :id="`tooltip-text-${_uid}`"
        class="toolTipIcon__text"
        v-html="text"
      />
      <div v-if="isArrowVisible" class="arrow" :style="tooltipArrowStyles" />
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ToolTipIcon",
  props: {
    text: { type: String, required: false, default: "Test" },
    isArrowVisible: { type: Boolean, default: false },
    isText: { type: Boolean, default: false },
    storeCoordsX: { type: String, default: "" },
    storeCoordsY: { type: String, default: "" },
    storeCounter: { type: Number, default: 0 },
  },
  data() {
    return {
      isVisible: false,
      xCoords: 0,
    };
  },
  computed: {
    tooltipArrowStyles() {
      return { left: `${this.xCoords}px` };
    },
    storeCoords() {
      return {
        left: `${this.storeCoordsX}px`,
        top: `${this.storeCoordsY}px`
      };
    },
    displayStoreCounter() {
      if (this.storeCounter > 0) {
        return this.storeCounter;
      } else {
        return '';
      }
    },
  },
  methods: {
    show(v) {
      this.isVisible = v;
    },
    showTooltip(event) {
      this.$nextTick(() => {
        setTimeout(() => {
          const infoElLeft = event.target.getBoundingClientRect().left;
          const textLeft = document
            .getElementById(`tooltip-text-${this._uid}`)
            .getBoundingClientRect().left;
          this.xCoords = infoElLeft - textLeft - 2;
        }, 100);
      });
    },
  },
};
</script>