export const overrides = {
	cs: {
		buyout: {
			need_visit_shop: 'Pro uskutečnění výkupu online je třeba mít vytvořenou objednávku na nové zařízení v našem eshopu',
			need_visit_shop_tutorial: 'kompletní návod tady',
			contractForm: {
				note: 'Číslo objednávky nového zařízení'
			},
			sell_online: 'Výkup zařízení online',
			send_info_email: 'Výkup zařízení na prodejně',
			thankYou: {
				onlineBuyout: {
					title: 'Informativní nacenění zařízení bylo odesláno na Vaši e-mailovou adresu pod číslem <b>{0}</b>.',
					text: 'Náš technik Vás bude kontaktovat v nejkratší možné době pro sjednání vyzvednutí starého zařízení.'
				}
			},
		}
	}
};