import router from '@/router';

export const config = {
	isURLHandlingEnabled: true,
	isOnlyOnlineBuyout: false,
	router: router,
	privacy_policy: {
		text:
			'Souhlasím s pravidly programu „{X}“ a se zpracováním osobních údajů potřebných pro účast v programu.',
		link_title: 'Vyměň svoje staré zařízení za nové online',
		link: 'https://www.istores.cz/podminky-online-vykupu_ma21114.html'
	}
};
