<template>
	<div class="bonusDevices">
		<div class="bonusDevices__item"><text-block identifier="bonusOffer__devices_1" /></div>
		<div class="bonusDevices__item"><text-block identifier="bonusOffer__devices_2" /></div>
		<div class="bonusDevices__item"><text-block identifier="bonusOffer__devices_3" /></div>
		<div class="bonusDevices__item"><text-block identifier="bonusOffer__devices_4" /></div>
		<div class="bonusDevices__item"><text-block identifier="bonusOffer__devices_5" /></div>
		<div class="bonusDevices__item"><text-block identifier="bonusOffer__devices_6" /></div>
	</div>
</template>

<script>
import { buyout } from 'js-mp';

const { TextBlock } = buyout.components;

export default {
	name: 'BonusDevices',
	components: {
		TextBlock
	}
};
</script>
